// Contexto

.Contexto{
    padding: 80px 0;
    height: auto;
    .textContexto{
        position: relative;
        text-align: center;
        h3{
            font-size: 20px;
            font-weight: 900;
            color: #000;
            text-transform: uppercase;
            line-height: 40px;
            text-align: left;
        }
        h2{
            font-size: 20px;
            font-weight: 900;
            color: #000;
            text-transform: uppercase;
            line-height: 40px;
            text-align: left;
        }
        p{
            color: #272727;
            text-align: left;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 1.08px;
        }
        ul{
            text-align: left;
            margin: 0 0 20px 0;
            padding: 0 0 0 20px;
        }
        a{
            color: var(--tertiaryColor);
            text-align: left;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 1.08px;
        }
    }
}
